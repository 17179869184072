import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY, { SCREEN_MASSIVE } from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';

import styled from '@emotion/styled';

interface ImageComponentProps {
  editable?: boolean;
  hasImage?: boolean;
}
interface HeroProps {
  onClick?: () => void;
  editable?: boolean;
}

const COUPLE_IMG_OFFSET = '40px';

export const HeroBanner = styled.div<{ hasImage: boolean }>`
  ${({ hasImage }) => `
    position: relative;
    padding-bottom: ${COUPLE_IMG_OFFSET};

    ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
      margin: 0 -20px;
    }
    ${
      hasImage &&
      `
      &:before { 
        content: '';
        z-index: 1; 
        width: 100%;
        height: calc(100% - ${COUPLE_IMG_OFFSET});
        display: block;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 70%);
  
        ${MEDIA_QUERY.DESKTOP} {
          border-radius: 16px;
        }
      }
    `
    }
    
    
  `}
`;

export const HeroBannerText = styled.div<{ darken: boolean }>`
  ${({ darken }) => `
    position: absolute;
    top: 0;
    bottom: ${COUPLE_IMG_OFFSET};
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
    justify-content: center;
    z-index: ${ZINDEX.middle}; 

    color: ${darken ? COLORS3.BLACK_100 : COLORS3.WHITE_100};
  `}
`;

export const HeroBannerImage = styled.img<ImageComponentProps>`
  ${({ editable, hasImage }) => `
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: ${SCREEN_MASSIVE}px;
    max-height: 266px;
    height: 266px;

    background-color: ${COLORS3.CLOUD_100};
    object-fit: cover;
    background-size: cover;
    
    ${editable && !hasImage ? `border: 1px dashed ${COLORS3.BLACK_100};` : ''}

    ${MEDIA_QUERY.DESKTOP} {
      border-radius: 16px;
    }
    ${
      !editable &&
      `
      ${MEDIA_QUERY.DESKTOP_XL} {
        max-height: revert;
        height: 320px;
      }
  `
    }
    

  `}
`;

export const HeroCoupleImage = styled.img<ImageComponentProps>`
  ${({ hasImage }) => `
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: ${COLORS3.CLOUD_100};
    background-size: cover;
    background-position: center;
    border: 4px solid ${COLORS3.WHITE_100};

    ${MEDIA_QUERY.MOBILE} {
      height: 64px;
      width: 64px;
    }

    ${
      !hasImage &&
      `
      border: 1px dashed ${COLORS3.BLACK_100};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${COLORS3.BLACK_100};
    `
    }
  `}
`;

export const HeroCoupleName = styled.div`
  text-align: center;
  font-size: ${FONT.FONT_SIZE_SEMI_HUGE};
  line-height: 1;
`;

export const HeroWeddingDate = styled.div`
  text-align: center;
  padding-top: 24px;
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALL)}
`;

export const HeroEditButtonText = styled.span`
  margin-left: 12px;
`;

export const HeroEditButton = styled(ButtonV3)<{ editable?: boolean }>`
  display: ${(props) => (props.editable ? 'block' : 'none')};
  position: absolute;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    bottom: 16px;
    right: 16px;
    padding: 0;
    height: 44px;
    width: 44px;

    ${HeroEditButtonText} {
      display: none;
    }
  }

  ${MEDIA_QUERY.DESKTOP} {
    display: none;
    top: 24px;
    right: 24px;
  }
`;

export const HeroGreetingSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  width: 100%;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding: 8px 0 24px;
  }
`;

export const HeroGreetingContent = styled.span`
  color: ${COLORS3.BLACK_075};
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALL)}
  text-align: center;
`;

export const Hero = styled.div<HeroProps>`
  ${({ editable }) =>
    editable &&
    `
    cursor: pointer;

    ${MEDIA_QUERY.DESKTOP} {
      &:hover {
        opacity: 0.9;
        ${HeroEditButton} {
          display: inline-flex;
        }
      }
    }
  `}
`;
