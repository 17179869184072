import TextareaFieldV2 from '@zola/zola-ui/src/components/Form/TextareaFieldV2/TextareaFieldV2';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

import styled from '@emotion/styled';

export const CustomizeHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const ImageTileWrapper = styled.div`
  position: relative;
`;

export const UploadTile = styled.div`
  align-items: center;
  background-color: ${COLORS3.CLOUD_100};
  border: 1px dashed ${COLORS3.BLACK_100};
  border-radius: 16px;
  display: flex;
  flex-shrink: 0;
  margin-top: 8px;
  min-height: 116px;
  padding: 24px 16px;
`;

export const UploadText = styled.div`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALL)};
  color: ${COLORS3.BLACK_100};
  display: flex;
  margin-right: 16px;

  svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

export const BannerPreviewTile = styled.div<{ image: string }>`
  ${({ image }) => `
    background: url(${image}) no-repeat center;
    background-size: cover;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 8px;
    max-height: 122px;
    min-height: 122px;
    padding-bottom: 23.5%;
    position: relative;
  `}
`;

export const CropImageCTA = styled.div`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALLER)};
  align-items: center;
  background: rgba(249, 249, 249, 0.7);
  border-radius: 8px;
  bottom: 16px;
  column-gap: 4px;
  cursor: pointer;
  display: flex;
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 16px;
  width: 77px;
`;

export const EditBannerCTAsWrapper = styled.div`
  column-gap: 8px;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;

  svg {
    cursor: pointer;
  }
`;

export const PhotoPreviewWrapper = styled.div`
  column-gap: 16px;
  display: flex;
`;
export const PhotoPreviewTile = styled(BannerPreviewTile)`
  cursor: pointer;
  height: 116px;
  padding-bottom: 0;
  width: 116px;
`;

export const EditPhotoCTAsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
`;

export const EditPhotoCTA = styled.div`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALLER)};
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  display: flex;
`;

export const StyledTextarea = styled(TextareaFieldV2)`
  margin-bottom: 8px;
`;
